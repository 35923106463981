<template>
  <div v-if="authUserIsCoach">
    <LoadingMessage v-if="isLoading" :message="'Fetching exercises...'"></LoadingMessage>

    <form @submit.prevent v-if="!isLoading" class="d-flex align-items-center bg-dark p-2 rounded">
      <div class="form-floating-cta-container">
        <div class="form-floating-cta">
          <fa-icon
            @click="resetFilter"
            v-if="keyword.length"
            :icon="['fas', 'times-circle']"
            role="button"
          ></fa-icon>
        </div>
        <input
          v-model.trim="keyword"
          ref="keyword"
          type="text"
          class="form-control form-control-dark"
          placeholder="Search exercise..."
        >
      </div>
      <IconButton
        @click="showModal = true"
        :icon="'plus'"
        class="btn btn-primary ms-2"
      ></IconButton>
      <IconButton
        @click="fetchItems"
        :icon="'sync-alt'"
        class="btn btn-primary ms-2"
      ></IconButton>
    </form>

    <div v-if="!isLoading" class="rounded border bg-white mt-2">
      <div class="table-responsive">
        <table class="table m-0">
          <thead>
            <tr>
              <th scope="col" class="align-baseline">Exercise name</th>
              <th scope="col" class="align-baseline">Video</th>
              <th scope="col" class="align-baseline"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!filteredItems.length">
              <td colspan="3">No exercises found</td>
            </tr>
            <ExerciseListItem
              @update="setItemForUpdate"
              v-for="(exercise, exerciseIndex) in filteredItems"
              :key="`key-${exercise.exerciseLibraryId}`"
              :exercise="exercise"
              :index="exerciseIndex + 1"
            ></ExerciseListItem>
          </tbody>
        </table>
      </div>
    </div>

    <portal to="modal">
      <transition name="modal">
        <ExerciseModal
          @close="hideModal"
          v-model="showModal"
          v-if="showModal"
          :exercise="selectedItem"
        ></ExerciseModal>
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ExerciseList',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    IconButton: () => import('@/components/button/IconButton'),
    ExerciseListItem: () => import('./ExerciseListItem.vue'),
    ExerciseModal: () => import('./ExerciseModal.vue'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsCoach']),
    ...mapState('library', ['libraryItems']),
    filteredItems() {
      const { keyword } = this;
      if (keyword.length) {
        const q = keyword.toLowerCase();
        return this.libraryItems.filter((item) => {
          const { name, description } = item;
          return name.toLowerCase().indexOf(q) >= 0 || description.toLowerCase().indexOf(q) >= 0;
        });
      }
      return this.libraryItems;
    },
  },
  methods: {
    async fetchItems() {
      this.isLoading = true;
      this.keyword = '';
      try {
        await this.$store.dispatch('library/fetchItems');
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
    setItemForUpdate(item) {
      this.selectedItem = item;
      this.showModal = true;
    },
    resetFilter() {
      this.keyword = '';
      this.$refs.keyword.focus();
    },
    hideModal() {
      this.showModal = false;
      this.selectedItem = null;
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      keyword: '',
      selectedItem: null,
    };
  },
  mounted() {
    this.fetchItems();
  },
};
</script>

<style lang="scss" scoped>
th[scope="col"] {
  &:nth-child(1) {
    width: 1%;
  }
  &:nth-child(1) {
    width: 89%;
  }
  &:nth-child(2) {
    width: 10%;
  }
  &:nth-child(3) {
    width: 1%;
  }
}
</style>
